import axios, {
  AxiosError,
  AxiosResponse,
  HeadersDefaults,
  RawAxiosRequestHeaders,
} from "axios";
import { Session } from "next-auth";
import { createContext, useContext, useMemo } from "react";
import { toast } from "react-toastify";

import { API_HOST } from "../../config/server";
import { logger } from "../../utils/logger";
import { ApiDataNotFoundError, ApiRequestFailedError } from "../error";

export type AxiosOptions = {
  baseUrl?: string;
  headers?: {};
};

type AxiosCustomError = AxiosError<{ error: { message: string } }, {}>;

class ApiResponseDecodeError extends ApiRequestFailedError {}

const generateErrorMessage = (e: AxiosCustomError): string => {
  switch (e.response?.status) {
    case 400:
      return e.response?.data.error.message;
    case 401:
      return "認証に失敗しました";
    default:
      return "エラーが発生しました";
  }
};

export const buildAuthHeader = (
  session?: Session | null,
):
  | { headers: RawAxiosRequestHeaders | Partial<HeadersDefaults> }
  | undefined => {
  if (session && session.accessToken)
    return { headers: { Authorization: `Bearer ${session.accessToken}` } };
  return undefined;
};

export const buildApi = ({ baseUrl = API_HOST, headers }: AxiosOptions) => {
  const customHeaders = {
    ...headers,
    ...{ "Content-Type": "application/json" },
  };

  const apiClient = axios.create({
    baseURL: baseUrl,
    responseType: "json",
    headers: customHeaders,
  });

  apiClient.interceptors.response.use(
    (response: AxiosResponse) => {
      return Promise.resolve(response);
    },
    async (error: AxiosCustomError) => {
      let err: ApiRequestFailedError;
      let message = error?.response?.data.error.message;
      const status = error.response?.status;
      const url = error?.response?.config.url;

      if (status === 401) return Promise.reject();

      try {
        if (status === 404) {
          err = new ApiDataNotFoundError(
            `status:${status}  url:${url}  body_text=${message}`,
          );
        } else {
          err = new ApiRequestFailedError(
            `status:${status}  url:${url}  body_text=${message}`,
          );
          toast.error(generateErrorMessage(error));
        }
      } catch (e) {
        err = new ApiResponseDecodeError(`status:${status}  url:${url}`);
      }

      logger.error(err, err.message);
      return Promise.reject(err);
    },
  );

  return apiClient;
};

const AxiosContext = createContext(buildApi({}));

export const useApi = () => useContext(AxiosContext);

export const AxiosProvider: React.FunctionComponent<{
  session: Session;
  children?: React.ReactNode;
}> = ({ session, children }) => {
  const value = useMemo(
    () =>
      session && session.accessToken
        ? buildApi({
            headers: { Authorization: `Bearer ${session.accessToken}` },
          })
        : buildApi({}),
    [session],
  );

  return (
    <AxiosContext.Provider value={value}>{children}</AxiosContext.Provider>
  );
};
