import { css } from "@emotion/css";
import styled from "@emotion/styled";
import {
  Background,
  Breadcrumbs,
  CrossIcon,
  Modal,
} from "@ibeckinc/ui-elements";
import Head from "next/head";
import React, { FunctionComponent, useCallback, useMemo } from "react";

import { useMobile } from "../../hooks/useMobile";
import { pagesPath } from "../../lib/$path";
import { device, fullURL } from "../../utils";
import Heading from "../Heading";

interface Props {
  className?: string;
  breadcrumbs?: {
    item: string;
    path: string;
  }[];
  breadcrumbsBottom?: boolean;
  children: React.ReactElement | (React.ReactElement | undefined)[];
  openSidebar?: boolean;
  reverse?: boolean;
  title?: string;
  onCloseSidebar?: () => void;
}

export const ContentLayout: FunctionComponent<Props> = ({
  breadcrumbs,
  breadcrumbsBottom,
  className,
  children,
  openSidebar = true,
  reverse = false,
  title,
  onCloseSidebar,
}) => {
  const { isMobile } = useMobile();

  const _children = Array.isArray(children)
    ? children.filter((child) => child != undefined)
    : children;
  const isSingleColumn = Array.isArray(_children)
    ? _children.length === 1
    : true;
  const isTripleColumn = Array.isArray(_children)
    ? _children.length === 3
    : false;

  const breadcrumbListItemSchema = useMemo(
    () =>
      breadcrumbs
        ? breadcrumbs.map((br, idx) => ({
            "@type": "ListItem",
            position: idx + 2,
            name: br.item,
            item: fullURL(br.path),
          }))
        : [],
    [breadcrumbs],
  );

  const renderBreadcrumbs = useCallback(
    () => (
      <BreadcrumbsWrapper
        mt={breadcrumbsBottom ? 16 : undefined}
        mb={breadcrumbsBottom ? -104 : undefined}
      >
        <Breadcrumbs>
          <Breadcrumbs.Item key={`breadcrumbs-top`} href="/">
            トップページ
          </Breadcrumbs.Item>
          {breadcrumbs
            ? breadcrumbs.map(({ item, path }, idx) =>
                path ? (
                  <Breadcrumbs.Item key={`breadcrumbs-item-${idx}`} href={path}>
                    {item}
                  </Breadcrumbs.Item>
                ) : (
                  <Breadcrumbs.Item key={`breadcrumbs-item-${idx}`}>
                    {item.length > 20 ? `${item.slice(0, 20)}...` : item}
                  </Breadcrumbs.Item>
                ),
              )
            : null}
        </Breadcrumbs>
      </BreadcrumbsWrapper>
    ),
    [breadcrumbs, breadcrumbsBottom],
  );

  const renderBreadcrumbsHeader = useCallback(
    () => (
      <PageHeadingSection>
        {!isMobile() && !breadcrumbsBottom && renderBreadcrumbs()}
        {title && <Heading.H1>{title}</Heading.H1>}
      </PageHeadingSection>
    ),
    [breadcrumbsBottom, title, isMobile, renderBreadcrumbs],
  );

  const renderSidebar = (
    children?: React.ReactElement<
      any,
      string | React.JSXElementConstructor<any>
    >,
  ) =>
    !isSingleColumn && !isMobile() ? (
      <Sidebar reverse={reverse}>{children}</Sidebar>
    ) : (
      <_Modal
        contentClassName={modalContentStyle}
        open={openSidebar}
        overlay={false}
      >
        <Sidebar reverse={false}>
          <Close>
            <CrossIcon onClick={onCloseSidebar} />
          </Close>
          {children}
        </Sidebar>
      </_Modal>
    );

  return (
    <>
      <Head>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "トップページ",
                item: fullURL(pagesPath.$url().pathname),
              },
              ...breadcrumbListItemSchema,
            ],
          })}
        </script>
      </Head>
      {Array.isArray(_children) ? (
        <Root className={className} withBreadcrumbs={!!breadcrumbs}>
          {breadcrumbs && renderBreadcrumbsHeader()}
          {isTripleColumn && _children[0]}
          <Content>
            <Main single={isSingleColumn} reverse={reverse}>
              {isTripleColumn ? _children[1] : _children[0]}
            </Main>
            {renderSidebar(isTripleColumn ? _children[2] : _children[1])}
          </Content>
          {breadcrumbsBottom && renderBreadcrumbs()}
        </Root>
      ) : (
        <Root className={className} withBreadcrumbs={!!breadcrumbs}>
          {breadcrumbs && renderBreadcrumbsHeader()}
          <Content>
            <Main single={isSingleColumn} reverse={reverse}>
              {_children}
            </Main>
          </Content>
          {breadcrumbsBottom && renderBreadcrumbs()}
        </Root>
      )}
    </>
  );
};

const Root = styled.div<{ withBreadcrumbs: boolean }>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  margin: 0 auto;
  padding: ${({ withBreadcrumbs }) => (withBreadcrumbs ? "0" : "64px 0 0 0")};
  width: 1000px;

  @media ${device.mobile} {
    flex-direction: column;
    margin: 0px auto;
    padding: ${({ withBreadcrumbs }) =>
      withBreadcrumbs ? "0 20px" : "24px 20px 0 20px"};
    width: 100%;
  }
`;

const PageHeadingSection = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const BreadcrumbsWrapper = styled.div<{ mb?: number; mt?: number }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  ${({ mb }) => (mb ? `margin-bottom: ${mb}px;` : "")}
  ${({ mt }) => (mt ? `margin-top: ${mt}px;` : "")}
`;

const Content = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  width: 100%;

  @media ${device.mobile} {
    flex-direction: column;
    margin: 0;
    padding: 0;
    width: 100%;
  }
`;

const Main = styled.main<{ single: boolean; reverse: boolean }>`
  order: ${({ reverse }) => (reverse ? 1 : -1)};
  width: ${({ single }) => (single ? "100%" : "680px")};

  @media ${device.mobile} {
    margin: 0;
    width: 100%;
  }
`;

const Sidebar = styled.aside<{ reverse: boolean }>`
  margin: ${({ reverse }) => (reverse ? "0 32px 0 0" : "0")};
  order: ${({ reverse }) => (reverse ? -1 : 1)};
  width: 288px;

  @media ${device.mobile} {
    background: ${Background.Base};
    box-sizing: border-box;
    padding: 0 20px 64px;
    overflow-y: scroll;
    height: 100%;
    width: 100%;
  }
`;

const _Modal = styled(Modal)<{ _height?: number }>`
  background: ${Background.Base};
  box-sizing: border-box;
  overflow-y: scroll;
  z-index: 1500;
`;

const modalContentStyle = css`
  box-sizing: border-box;
  overflow-y: scroll;
  max-height: 100vh;
  width: 100%;
  height: 100%;
  z-index: 1500;
`;

const Close = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 48px;
  width: 100%;
`;
