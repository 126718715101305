import { css } from "@emotion/css";
import styled from "@emotion/styled";
import {
  AccountCircleIcon as UserIcon,
  Background,
  BellIcon,
  Button,
  CrossIcon,
  HamburgerIcon,
  Modal,
  Typography,
} from "@ibeckinc/ui-elements";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useMobile } from "../../hooks/useMobile";
import { useWindowSize } from "../../hooks/useWindowSize";
import { pagesPath } from "../../lib/$path";
import { device } from "../../utils";
import { ServiceLogo } from "../Logo";
import { NextLink } from "../NextLink";
import NotificationList from "../NotificationList/NotificationList";
import { Badge } from "./Badge";
import HEADER_LINKS from "./HeaderLinks";
import MobileMenu from "./MobileMenu";
import Popover from "./Popover";
import { getUnreadNotificationIds } from "./query";
import UserMenu from "./UserMenu";

const { Paragraphs } = Typography;

type Props = {
  className?: string;
  disabled?: boolean;
};

export const Header: FunctionComponent<Props> = ({
  className,
  disabled = false,
}) => {
  const { width: windowWidth } = useWindowSize();
  const { isMobile } = useMobile();
  const router = useRouter();
  const { data: session, status } = useSession();

  const [notificationOpen, setNotificationOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [unread, setUnread] = useState(true);

  const handleBellIconClick = useCallback(() => {
    setMenuOpen(false);
    setNotificationOpen((prev) => !prev);
  }, []);

  const handleUserIconClick = useCallback(() => {
    setNotificationOpen(false);
    setMenuOpen((prev) => !prev);
  }, []);

  const handleNotificationClose = useCallback(
    () => setNotificationOpen(false),
    [],
  );

  const handleMenuClick = useCallback(() => setMenuOpen(false), []);

  const handleMobileMenuClose = useCallback(() => setMobileMenuOpen(false), []);

  const handleMobileMenuOpen = useCallback(() => setMobileMenuOpen(true), []);

  const signInURL = useMemo(
    () =>
      router.asPath !== "/"
        ? `${pagesPath.sign_in.$url().pathname}?callbackUrl=${router.asPath}`
        : pagesPath.sign_in.$url(),
    [router],
  );

  useEffect(() => {
    setNotificationOpen(false);
    setMenuOpen(false);
    setMobileMenuOpen(false);
  }, [router.route]);

  useEffect(() => {
    if (session && !notificationOpen && unread)
      getUnreadNotificationIds({ session }).then((ids) => {
        if (ids.length > 0) setUnread(true);
        else setUnread(false);
      });
  }, [notificationOpen, session, unread]);

  return (
    <>
      <Root className={className}>
        <LogoLink href={pagesPath.$url()}>
          <ServiceLogo height={isMobile() ? 24 : 28} />
        </LogoLink>
        {!disabled && (
          <>
            {!isMobile() ? (
              <LinkList>
                <HeaderLinkList>
                  {HEADER_LINKS.map(({ link, href }, idx) => (
                    <HeaderLinkAnchor key={idx} href={href}>
                      <Paragraphs bold="bold" color="blueBlack">
                        {link}
                      </Paragraphs>
                    </HeaderLinkAnchor>
                  ))}
                </HeaderLinkList>
                {status === "unauthenticated" ? (
                  <ButtonList>
                    <NextLink href={signInURL}>
                      <_Button color="secondary" size="small" variant="outline">
                        ログイン
                      </_Button>
                    </NextLink>
                    <NextLink href={pagesPath.sign_up.$url()}>
                      <_Button size="small">会員登録</_Button>
                    </NextLink>
                  </ButtonList>
                ) : (
                  <ActionIconContainer>
                    <_Badge show={unread}>
                      <BellIcon size={30} onClick={handleBellIconClick} />
                    </_Badge>
                    <UserIcon size={30} onClick={handleUserIconClick} />
                  </ActionIconContainer>
                )}
              </LinkList>
            ) : mobileMenuOpen ? (
              <CrossIcon
                id="brand-cross-icon"
                color="brand"
                onClick={handleMobileMenuClose}
              />
            ) : (
              <HamburgerIcon
                id="brand-hamburger-icon"
                color="brand"
                onClick={handleMobileMenuOpen}
              />
            )}
          </>
        )}
      </Root>
      {notificationOpen && (
        <PopoverWrapper>
          <NotificationPopover
            size="large"
            speechBubble={windowWidth < 1000}
            onClickOverlay={handleNotificationClose}
          >
            <NotificationList limit={5} />
          </NotificationPopover>
        </PopoverWrapper>
      )}
      {menuOpen && (
        <PopoverWrapper>
          <UserMenuPopover
            size="large"
            speechBubble={windowWidth < 1000}
            onClickOverlay={handleMenuClick}
          >
            <UserMenu unread={unread} />
          </UserMenuPopover>
        </PopoverWrapper>
      )}
      {mobileMenuOpen && (
        <_Modal
          contentClassName={modalContentStyle}
          open={mobileMenuOpen}
          overlay={false}
        >
          <MobileMenu />
        </_Modal>
      )}
    </>
  );
};

const Root = styled.header`
  background: ${Background.Base};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
  height: 64px;
  width: 100%;

  @media ${device.pc} {
    min-width: 1000px;
  }

  @media ${device.mobile} {
    padding: 0 24px;
    height: 48px;
    z-index: 1400;
  }
`;

const _Button = styled(Button)`
  min-width: 106px;
`;

const LogoLink = styled(NextLink)`
  display: flex;
  align-items: center;
  gap: 16px;
  text-decoration: none;
`;

const LinkList = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
`;

const HeaderLinkList = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 600px;
  width: 100%;
  height: 100%;
  margin-right: 24px;
`;

const HeaderLinkAnchor = styled(NextLink)`
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 112px;
  position: relative;
  text-decoration: none;

  &::after {
    content: "";
    display: block;
    height: 3px;
    background: ${Background.Brand};
    position: absolute;
    bottom: 0px;
    left: 0;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    width: 0;
  }

  &:hover::after {
    width: 100%;
  }
`;

const ButtonList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const ActionIconContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

const _Badge = styled(Badge)`
  height: 30px;
  width: 30px;
`;

const PopoverWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  position: absolute;
  top: 0px;
  width: 100%;
`;

const NotificationPopover = styled(Popover)`
  position: fixed;
  margin-top: 80px;
  margin-right: 64px;
  z-index: 1200;
`;

const UserMenuPopover = styled(Popover)`
  position: fixed;
  margin-top: 80px;
  margin-right: 10px;
  z-index: 1200;
`;

const _Modal = styled(Modal)`
  background: ${Background.Base};
  margin-top: 48px;
  height: 100%;
  z-index: 2000;
`;

const modalContentStyle = css`
  box-sizing: border-box;
  overflow-y: scroll;
  max-height: 100%;
  width: 100%;
  height: 100%;
  z-index: 2000;
`;
