import { resolveHref } from "next/dist/client/resolve-href";
import NextHead from "next/head";
import { useRouter } from "next/router";
import React, { FunctionComponent, useMemo } from "react";
import { UrlObject } from "url";

import { APP_ENV } from "../config/server";
import { fullURL } from "../utils";

const articleSearchPathRegex = new RegExp(/^\/articles\/search\/.*/);
const noIndexPath = [
  "/account",
  "/private",
  "/email_confirmation_sent",
  "/email_confirmation",
  "/reset_password_requested",
  "/reset_password",
  "/404",
];
const orgSchemaPath = ["/", "/about"];
const orgSchema = {
  "@context": "https://schema.org",
  "@type": "Organization",
  image:
    "https://static.wixstatic.com/media/6598b0_d3d8027c6fce47e4b47ae4393f3f3ad1~mv2_d_2776_1608_s_2.png/v1/fill/w_158,h_92,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/6598b0_d3d8027c6fce47e4b47ae4393f3f3ad1~mv2_d_2776_1608_s_2.png",
  url: "https://www.ibeck.co.jp/",
  sameAs: ["https://www.ibeck.co.jp/about"],
  logo: "https://static.wixstatic.com/media/6598b0_d3d8027c6fce47e4b47ae4393f3f3ad1~mv2_d_2776_1608_s_2.png/v1/fill/w_158,h_92,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/6598b0_d3d8027c6fce47e4b47ae4393f3f3ad1~mv2_d_2776_1608_s_2.png",
  name: "iBECK Inc.",
  description:
    "iBECK Inc. is an essential approach human resource development company that maximizes the potential of individuals and groups by cultivating essential thinkers, INNOVATORS, and LEADERS.",
  email: "contact@ibeck.co.jp",
  telephone: "+81-3-4405-7999",
  address: {
    "@type": "PostalAddress",
    streetAddress: "2-17-17, Hongo",
    addressLocality: "Bunkyo-ku",
    addressRegion: "Tokyo",
    addressCountry: "JP",
    postalCode: "1130033",
  },
};

export const Head: FunctionComponent<{
  title?: string;
  description?: string;
  image?: string;
  href?: UrlObject | string;
  ogType?: "article" | "website";
  children?: React.ReactNode;
}> = ({ title, description, image, children, href, ogType }) => {
  const router = useRouter();

  const orgSchemaIndex = useMemo(
    () => orgSchemaPath.some((path) => path === router.pathname),
    [router],
  );
  const isNoIndex = useMemo(
    () =>
      APP_ENV !== "prd" ||
      noIndexPath.some((path) => router.pathname.includes(path)) ||
      articleSearchPathRegex.test(router.pathname),
    [router],
  );

  let joinedTitle = title
    ? [title, "Alternative Careers"].join(" | ")
    : "Alternative Careers";

  const canonicalHref = (() => {
    if (href) {
      const [, resolvedAs] = resolveHref(router, href, true);
      if (resolvedAs) return fullURL(resolvedAs);
    }
  })();

  if (canonicalHref === "/")
    joinedTitle = "オルタナティブキャリア | 学生のためのキャリア支援サイト";

  return (
    <NextHead>
      {isNoIndex && <meta name="robots" content="noindex" />}
      <title>{joinedTitle}</title>
      {orgSchemaIndex && (
        <script type="application/ld+json">{JSON.stringify(orgSchema)}</script>
      )}
      <meta property="og:title" content={joinedTitle} key="og:title" />
      <meta
        property="og:site_name"
        content="オルタナティブキャリア | 学生のためのキャリア支援サイト"
      />

      {description && (
        <>
          <meta name="description" content={description} />
          <meta
            property="og:description"
            content={description}
            key="og:description"
          />
        </>
      )}

      {canonicalHref && <meta property="og:url" content={canonicalHref} />}

      {image && <meta property="og:image" content={image} key="og:image" />}

      {ogType && <meta property="og:type" content={ogType} />}

      <meta name="twitter:card" content="summary_large_image" />

      {canonicalHref && <link rel="canonical" href={canonicalHref} />}

      {children}
    </NextHead>
  );
};
