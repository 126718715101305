import type { OptionalQuery as OptionalQuery_f08ty3 } from '../pages/account/seminars';
import type { OptionalQuery as OptionalQuery_gg8ttn } from '../pages/seminars';
import type { OptionalQuery as OptionalQuery_132y4ze } from '../pages/sign_up';

export const pagesPath = {
  "$404": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/404' as const, hash: url?.hash })
  },
  "about": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/about' as const, hash: url?.hash })
  },
  "account": {
    "bookmark": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/account/bookmark' as const, hash: url?.hash })
    },
    "career_profile": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/account/career-profile' as const, hash: url?.hash })
    },
    "interviews": {
      _id: (id: string | number) => ({
        "reservation": {
          $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/account/interviews/[id]/reservation' as const, query: { id }, hash: url?.hash })
        }
      })
    },
    "profile": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/account/profile' as const, hash: url?.hash })
    },
    "seminars": {
      $url: (url?: { query?: OptionalQuery_f08ty3 | undefined, hash?: string | undefined } | undefined) => ({ pathname: '/account/seminars' as const, query: url?.query, hash: url?.hash })
    },
    "setting": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/account/setting' as const, hash: url?.hash })
    },
    "watchlist": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/account/watchlist' as const, hash: url?.hash })
    }
  },
  "article": {
    _id: (id: string | number) => ({
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/article/[id]' as const, query: { id }, hash: url?.hash })
    })
  },
  "articles": {
    _id: (id: string | number) => ({
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/articles/[id]' as const, query: { id }, hash: url?.hash })
    }),
    "search": {
      _id: (id: string | number) => ({
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/articles/search/[id]' as const, query: { id }, hash: url?.hash })
      }),
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/articles/search' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/articles' as const, hash: url?.hash })
  },
  "companies": {
    _id: (id: string | number) => ({
      "columns": {
        _columnId: (columnId: string | number) => ({
          $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/companies/[id]/columns/[columnId]' as const, query: { id, columnId }, hash: url?.hash })
        })
      },
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/companies/[id]' as const, query: { id }, hash: url?.hash })
    }),
    "private": {
      _id: (id: string | number) => ({
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/companies/private/[id]' as const, query: { id }, hash: url?.hash })
      })
    },
    "sponsored": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/companies/sponsored' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/companies' as const, hash: url?.hash })
  },
  "company": {
    _id: (id: string | number) => ({
      "column": {
        _columnId: (columnId: string | number) => ({
          $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/company/[id]/column/[columnId]' as const, query: { id, columnId }, hash: url?.hash })
        })
      },
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/company/[id]' as const, query: { id }, hash: url?.hash })
    }),
    "private": {
      _id: (id: string | number) => ({
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/company/private/[id]' as const, query: { id }, hash: url?.hash })
      })
    }
  },
  "contact": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/contact' as const, hash: url?.hash })
  },
  "email_confirmation": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/email_confirmation' as const, hash: url?.hash })
  },
  "email_confirmation_sent": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/email_confirmation_sent' as const, hash: url?.hash })
  },
  "jobseekers": {
    "welcome": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/jobseekers/welcome' as const, hash: url?.hash })
    }
  },
  "privacy_policy": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/privacy_policy' as const, hash: url?.hash })
  },
  "reset_password": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/reset_password' as const, hash: url?.hash })
  },
  "reset_password_requested": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/reset_password_requested' as const, hash: url?.hash })
  },
  "seminar": {
    _id: (id: string | number) => ({
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/seminar/[id]' as const, query: { id }, hash: url?.hash })
    }),
    "private": {
      _id: (id: string | number) => ({
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/seminar/private/[id]' as const, query: { id }, hash: url?.hash })
      })
    }
  },
  "seminars": {
    _id: (id: string | number) => ({
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/seminars/[id]' as const, query: { id }, hash: url?.hash })
    }),
    "private": {
      _id: (id: string | number) => ({
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/seminars/private/[id]' as const, query: { id }, hash: url?.hash })
      })
    },
    $url: (url?: { query?: OptionalQuery_gg8ttn | undefined, hash?: string | undefined } | undefined) => ({ pathname: '/seminars' as const, query: url?.query, hash: url?.hash })
  },
  "sign_in": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/sign_in' as const, hash: url?.hash })
  },
  "sign_up": {
    $url: (url?: { query?: OptionalQuery_132y4ze | undefined, hash?: string | undefined } | undefined) => ({ pathname: '/sign_up' as const, query: url?.query, hash: url?.hash })
  },
  "sitemap_xml": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/sitemap.xml' as const, hash: url?.hash })
  },
  "terms": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/terms' as const, hash: url?.hash })
  },
  $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/' as const, hash: url?.hash })
};

export type PagesPath = typeof pagesPath;
